import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { ShareRideService} from '../share-ride.service';
import { GoogleMapsAPIWrapper } from '@agm/core'; 
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { InitialiseService } from '../services/initialise.service';
// import { error } from 'console';

enum TravelMode {
  BICYCLING = 'BICYCLING',
  DRIVING = 'DRIVING',
  TRANSIT = 'TRANSIT',
  TWO_WHEELER = 'TWO_WHEELER',
  WALKING = 'WALKING',
}

@Component({
  selector: 'app-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.css'],
  animations: [
    
    trigger('slideInOutOne', [
      state('in', style({
        height: '0px',
      })),
      state('out', style({
        height: '40px',
      })),
      transition('in => out', animate('600ms ease-in-out')),
      transition('out => in', animate('600ms ease-in-out'))

    ]),
    trigger('slideInOutTwo', [
      state('in', style({
        height: '0px',
      })),
      state('out', style({
        height: '40px',
      })),
      transition('in => out', animate('600ms 700ms ease-in-out')),
      transition('out => in', animate('600ms ease-in-out'))

    ]),
    trigger('slideInOutThree', [
      state('in', style({
        height: '0px',
      })),
      state('out', style({
        height: '40px',
      })),
      transition('in => out', animate('600ms 1400ms ease-in-out')),
      transition('out => in', animate('600ms ease-in-out'))

    ])
  ]
})
export class TripComponent implements OnInit {
  currentLat: any;
  currentLng: any
  icon: any;
  helpMenuOpen: string;
  rotation: number;
  polyLine: any;
  driverLat: any;
  driverLng: any;
  driverLatitude: any;
  driverLongitude: any;
  closetLat: any;
  closetLng: any;
  pickUp: { lat: any; lng: any; };
  drop: { lat: any; lng: any; };
  rideType: any;
  driverState: any;
  driverCurrentState : any;
  pickUpTime: number;
  dropOffTime: number;
  rideId: any;
  rideRequestId: any
  driverRideId: any;
  driverPhone: any;
  legs: any;
  timerSubscription: any;
  timeLeft: number;
  isStopsUpdated: Boolean ;
  stopsFlag: boolean = false;
  driverDropLat: any;
  driverDropLng: any;
  actualPickUpTime: any;
  estimatedDropTime; any;
  height: string;
  orgZoom : number = 10;
  mapZoom: number = 14;
  eta:any;
  etd:any;
  displayMessageForRider: string;
  sharedRideType: any;
  showStop:boolean = false;
  otpToStartRide = "";
  otp={0: null, 1: null, 2: null, 3: null , 4: null, 5: null};
  displayOtpDialog = false;
  cancelRideDialog = false;
  otpTimerToDisplay;
  toggleCancelCta = false;
  isResendDisable = true;
  isVerifyOtpDisabled = true;
  isResendHide = false;
  platform = 'WEB';
  resendTitle = 'Didn’t Received The OTP Yet ?';
  resendCount = 0;
  token = "";
  @ViewChild('ngxotp')
  ngxOtp!: NgxOtpInputComponent;
  otpValue:any;
  otpEntered: boolean = false;
  resendOtpEnabled = false;
  attempts = 0;
  showFailedOTPMessage = false;
  input1: number | undefined ;
  input2: number | undefined ;
  input3: number | undefined ;
  input4: number | undefined ;
  input5: number | undefined ;
  input6: number | undefined ;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    numericInputMode: true,
    
  };

  cookieData:any = [];
  isIncorrectOtp = false;
  isOtpSent = false
  isOtpSentAtStart = false;
  subscription;


  constructor(private activatedRoute: ActivatedRoute, private router: Router , private _shareRide: ShareRideService, private _initialise: InitialiseService,private googleApi: GoogleMapsAPIWrapper, private _cookie: CookieService, private _loader: NgxSpinnerService){
    const id =this.activatedRoute.snapshot.paramMap.get('accessToken');
    this.token = id;
    _cookie.set('authToken', this.token)
    this.getDirection(id,this.subscription)
    
    this.subscription = interval(15000).subscribe(x => {
      this._shareRide.getDriverLocation(this.driverId,this.rideId).subscribe(res=>{
        if(res['response'])
        {
          this.driverCurrentState = this.driverState;
          this.driverLat = res['response'].latitude;
          this.driverLng = res['response'].longitude;
          
          this.driverState = res['response'].state;
          this.driverRideId = res['response'].rideId;
          this.isStopsUpdated = res['response'].isStopsUpdated;
          this.driverDropLat =res['response'].driverDropLat
          this.driverDropLng = res['response'].driverDropLong
          if(res['response'].etd && this.rideState == "RIDER_PICKED_UP")
            this.estimatedDropTime = res['response'].etd
        if(this.isDriverStateChange())
           this.getDirection(id,this.subscription)

        if(this.isArrived())
        { this.driverLat = this.drop.lat;
          this.driverLng = this.drop.lng;
       
          this.origin ={ lat: this.pickUp.lat ,lng : this.pickUp.lng}
          
          this.destination = { lat: this.drop.lat , lng: this.drop.lng}
        
          this.subscription.unsubscribe();
        }
        }
      },error => {
        this.router.navigateByUrl('notFound')
      })
      if(this.points){
        let latLng = new google.maps.LatLng(this.driverLat ,this.driverLng);
        if(this.driverLat)
        if(!google.maps.geometry.poly.isLocationOnEdge(latLng,this.polyLine,0.003))
            { this.origin ={ lat: this.driverLat, lng: this.driverLng};
          
              this.getDirection(id,this.subscription)
            }
        let closet = this.closest(latLng,this.polyLine.getPath().getArray())
        this.closetLat = closet[0].lat();
        this.closetLng = closet[0].lng();
      this.rotation= this.points[Number(closet[2])+5] ? this.angleFromCoordinate(this.driverLat,this.driverLng,this.points[Number(closet[2])+5].lat,this.points[Number(closet[2])+5].lng) : this.rotation;
       }
     });
  }

  ngOnInit(): void {

    setTimeout(()=>{
      this.isOtpSentAtStart = true;
    },4000)
    
    this.platform = this._cookie.get('platform') || 'WEB';
    this.helpMenuOpen = 'in';
   setTimeout(() => {
    this.move();
   }, 800);

   setTimeout(()=>{
    this.driverLatitude = this.driverLat;
    this.driverLongitude = this.driverLng;
    this.orgZoom = this.mapZoom
    this.showStop = true;
   },1200)

  }
  move(){
    this.helpMenuOpen = 'out'
  }
  title = 'blusmart-stellar';
  lat: number = 28.459497
  lng: number =77.026634
  i: number =0
  points: any
  public rider: String = ''
  public riderPhone : any = null
  public callingCode : any = null
  public origin: any;
  public destination: any;
  public waypoints: any;
  public driver: String = ''
  public driverId: String = ''
  public vehicleName: String =''
  public vehicleNumber: String =''
  public rideState : String =''
  public toggle: boolean = true
  public renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#2C66E3' ,strokeWeight: 2}
}
  public markerOptions = {
  // destination: {
  //     icon: '../assets/dropOff_1x.png'
  //   },
    waypoints: {
      icon: '../assets/stop_2x.png',
      infoWindow: `
      <h4>Stop<h4>
      `
    }
  }
  // styles = [
  //   {
  //     "elementType": "labels.text.fill",
  //     "stylers": [
  //       {
  //         "color": "#9495AB"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "landscape",
  //     "elementType": "geometry.fill",
  //     "stylers": [
  //       {
  //         "color": "#F2F2F2"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "landscape.man_made",
  //     "elementType": "geometry",
  //     "stylers": [
  //       {
  //         "color": "#F2F2F2"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi",
  //     "elementType": "labels.text.fill",
  //     "stylers": [
  //       {
  //         "color": "#B6B7C3"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.attraction",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.business",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.government",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.medical",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.park",
  //     "elementType": "labels.text.fill",
  //     "stylers": [
  //       {
  //         "color": "#69A668"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.place_of_worship",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.school",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "poi.sports_complex",
  //     "elementType": "labels.icon",
  //     "stylers": [
  //       {
  //         "color": "#A9AABC"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road",
  //     "elementType": "geometry.fill",
  //     "stylers": [
  //       {
  //         "color": "#FFFFFF"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road",
  //     "elementType": "geometry.stroke",
  //     "stylers": [
  //       {
  //         "color": "#FFFFFF"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.highway",
  //     "elementType": "labels.text.fill",
  //     "stylers": [
  //       {
  //         "color": "#9495AB"
  //       }
  //     ]
  //   },
  //   {
  //     "featureType": "road.highway.controlled_access",
  //     "elementType": "labels.text.fill",
  //     "stylers": [
  //       {
  //         "color": "#9495AB"
  //       }
  //     ]
  //   }
  // ]
  styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#abb5bf"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#a0a0b0"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "poi.school",
    "elementType": "labels.icon",
    "stylers": [
      {
        "color": "#828297"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#c9c9c9"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#cce6ff"
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
]

  stops =[]
  getStatus(status: any){
    this.polyLine = new google.maps.Polyline({
      path: status.routes[0].overview_path
    });
    this.points =  JSON.parse(JSON.stringify(status.routes[0].overview_path))
    if(this.points){
      let latLng = new google.maps.LatLng(this.driverLat ,this.driverLng);
      let closet = this.closest(latLng,this.polyLine.getPath().getArray())
      this.closetLat = closet[0].lat();
      this.closetLng = closet[0].lng();
      this.rotation= this.points[Number(closet[2])+5] ? this.angleFromCoordinate(this.driverLat,this.driverLng,this.points[Number(closet[2])+5].lat,this.points[Number(closet[2])+5].lng) : this.rotation;
     }
     this.getTimeLeft()
  }

  getTimeLeft(){
    let directionsService = new google.maps.DirectionsService()
    let  route = {
      origin: { lat: this.driverLat, lng : this.driverLng},
      destination:{ lat: this.destination.lat, lng : this.destination.lng},
      travelMode: TravelMode.DRIVING
    }
   
    directionsService.route(route,
      (response) => { 
        if(response){
          this.legs = response.routes[0].legs[0];
          this.startTimer()
        }
  })
    
}

  getDirection(urlToken,subscription) {
    this._initialise.getRideData().subscribe(res=>{
      let response = res
      if(response['statusCode'] == 200)
      { //console.log(response)
        let rideData = response['response'];
        
        this._cookie.set('rideType', rideData['rideType'])
        // otp to start ride
        this.otpToStartRide = rideData.otpToStart || rideData.verificationDetailsDto?.pin || '';

        //toggle cancel CTA
        this.toggleCancelCta = rideData['showRideCancellationCta']

        if(rideData.rideType == 'POOLING'){
          this.displayMessageForRider = rideData.shareTripDriverOnTheWayMessage;
        }
        if(response['response'].rideType != 'POOLING'){
          this.rider =response['response']['rider'] ? response['response']['rider']['firstName'] : response['response']['otherFlags']['rideRequestOtherFlagsRiderDto']['riderName']
          this.riderPhone = response['response']['rider'] ? response['response']['rider']['phoneNumber'] : response['response']['otherFlags']['rideRequestOtherFlagsRiderDto']['phoneNumber']
          this.callingCode = response['response']['rider'] ? response['response']['rider']['callingCode'] : response['response']['otherFlags']['rideRequestOtherFlagsRiderDto']['callingCode']
        } 
        let driverCallingCode = response['response']['driver']['callingCode'] ? response['response']['driver']['callingCode'] : '+91'
        if(response['response']['driver']){
          this.driver = response['response']['driver']['firstName']+' '+ response['response']['driver']['lastName']
          this.driverPhone = `${driverCallingCode}-${response['response']['driver']['phoneNumber']}`
          this.driverId = response['response']['driverId']
          this.vehicleName = response['response']['displayName']
          this.vehicleNumber = response['response']['vehicleNumber']
        }
        this.rideId = rideData['id']
        this.rideRequestId = rideData['rideRequestId']
        this._cookie.set('rideRequestId', this.rideRequestId);
        this.sharedRideType = response['response'].otherFlags.shareRideType;
      
       
        this.rideState = response['response']['rideState']
        this.rideType= response['response'].rideType;
        this.actualPickUpTime = response['response'].pickUpTimestamp ? response['response'].pickUpTimestamp : null ;
        this.estimatedDropTime = response['response'].estimatedCompletionTimestamp ? response['response'].estimatedCompletionTimestamp : null;

        this.pickUp ={ lat: response['response'].pickUpLat, lng: response['response'].pickUpLong };
       if(this.rideType == 'POOLING'){
        this.drop = { lat: response['response'].dropLat, lng: response['response'].dropLong };
       }
        if(this.isArrived() || this.isNotRental())
        this.drop ={ lat: response['response'].dropLat, lng: response['response'].dropLong };
        if(this.rideState == 'NO_SHOW' || this.rideState == 'CANCELLED')
          { subscription.unsubscribe();
            this.router.navigateByUrl('notFound')
          }

        this._shareRide.getDriverLocation(this.driverId,this.rideId).subscribe(res=>{
          if(res['response'])
           {this.driverLat = res['response'].latitude;
          
            this.driverLng = res['response'].longitude;
            this.driverState = res['response'].state;
            this.driverRideId = res['response'].rideId;
            this.isStopsUpdated = res['response'].isStopsUpdated;
            this.driverDropLat =res['response'].driverDropLat
            this.driverDropLng = res['response'].driverDropLong
            this.eta = res['response'].eta;
            this.etd = res['response'].etd;
            if(res['response'].etd && this.rideState == "RIDER_PICKED_UP")
                this.estimatedDropTime = res['response'].etd
            if( this.isArrived())
               {this.driverLat = this.drop.lat;
                this.driverLng = this.drop.lng;
                
                if(this.rideType != 'POOLING'){
                  this.origin = { lat: this.pickUp.lat ,lng: this.pickUp.lng}
               
                }
                
              }
            let waypoint = []
       
            if(this.driverRideId != this.rideId && !this.isArrived() && this.rideType != 'POOLING')
               {  
                  let wt = {
                      location : { lat : this.driverDropLat, lng: this.driverDropLng},
                      stopover : true
                    }
                  
                  waypoint.push(wt)
                  this.waypoints = waypoint
                
                  if(this.isWaitingForPickUp())
                    this.origin =  {lat: this.driverLat, lng: this.driverLng };
                  else
                    this.origin =  {lat: this.pickUp.lat, lng: this.pickUp.lng };
               } else if(this.isWaitingForPickUp()){
                if(this.rideType != 'POOLING'){
                 
                  this.origin =  {lat: this.driverLat, lng: this.driverLng };
                }
                 
               }
               else
               {
                if(this.rideType != 'POOLING'){
                  let wt = {
                    location : { lat : this.driverLat, lng: this.driverLng},
                    stopover : true
                  }
                  waypoint.push(wt)
                  this.waypoints = waypoint;
                  
                  this.origin =  {lat: this.pickUp.lat, lng: this.pickUp.lng };
                }
               
               }
           }
                    
           if(this.isNotRental() || !this.stopsFlag)
           this.destination = this.isWaitingForPickUp() ? { lat: this.pickUp.lat, lng: this.pickUp.lng } : { lat: this.drop.lat, lng: this.drop.lng };
          
          })
        if(this.isArrived())
        {  this.driverLat = this.drop.lat;
           this.driverLng = this.drop.lng;
           
           subscription.unsubscribe();
        }
        this.icon={
          url: ('../../assets/carIcon.svg'),
          scaledSize: {
            height: 60,
            width: 60
          }
        };
        let stopsList = response['response'].stopsList
        this.stops =[]
        if(!this.isNotRental() && Object.keys(stopsList).length ==1){
          for (const key of Object.keys(stopsList)) {
            let stop = { type: stopsList[key].type ,
                         location: response['response'].stopsList[key].place.indexOf(",") == -1 ? response['response'].stopsList[key].place : stopsList[key].place.substring(0, response['response'].stopsList[key].place.indexOf(",")) , 
                         time : stopsList[key]['pointDto'] ? stopsList[key]['pointDto'][0].time * 1000   + this.stops[Number(key)-1].time : response['response'].estimatedStartTimestamp,
                         status : stopsList[key].status,
                         lat:  stopsList[key].lat,
                         lng:  stopsList[key].lng,
                         riders: stopsList[key].riders
                        }
            this.stops.push(stop) }
         }else if(Object.keys(stopsList).length >=2)
        {  
         for (const key of Object.keys(stopsList)) {
            let stop = { type: stopsList[key].type ,
                         location: response['response'].stopsList[key].place.indexOf(",") == -1 ? response['response'].stopsList[key].place : stopsList[key].place.substring(0, response['response'].stopsList[key].place.indexOf(",")) , 
                         time : stopsList[key]['pointDto'] ? stopsList[key]['pointDto'][0].time * 1000   + this.stops[Number(key)-1].time : response['response'].estimatedStartTimestamp,
                         status : stopsList[key].status,
                         lat:  stopsList[key].lat,
                         lng:  stopsList[key].lng,
                         riders: stopsList[key].riders}
            this.stops.push(stop)
        }
        for (let stop in this.stops){
          { if(this.rideState == 'RIDER_PICKED_UP'){
               this.stopsFlag = true;
                if(this.stops[stop].status == 'REACHED' && this.rideType != 'POOLING')
                   this.pickUp = { lat : this.stops[stop].lat , lng : this.stops[stop].lng}
                if(this.stops[stop].status == 'LEFT')
                  {
                    if(this.rideType != 'POOLING'){
                      this.destination = { lat : this.stops[stop].lat , lng : this.stops[stop].lng}
                      this.drop = { lat : this.stops[stop].lat , lng : this.stops[stop].lng}
                    }
                
                   }
                   
            }else{
              if(this.rideType != 'POOLING'){
                this.pickUp = { lat : this.stops[0].lat , lng : this.stops[0].lng}
                this.drop = { lat : this.stops[this.stops.length -1].lat , lng : this.stops[this.stops.length -1].lng} 
              }
            
            }
          }
        }
       } else{
        this.stops = [{ type: 'PICKUP' ,
                      location: response['response'].pickUpLocation.indexOf(",") == -1 ? response['response'].pickUpLocation : response['response'].pickUpLocation.substring(0, response['response'].pickUpLocation.indexOf(",")) , 
                      time : this.actualPickUpTime ? this.actualPickUpTime : response['response'].estimatedStartTimestamp },
                      { type: 'DROP' ,
                      location: response['response'].dropLocation.indexOf(",") == -1  ? response['response'].dropLocation :response['response'].dropLocation.substring(0, response['response'].dropLocation.indexOf(",")) , 
                      time : response['response'].estimatedCompletionTimestamp }]
       }
      }
   

      if(this.rideType == 'POOLING'){
     
        
        this.stopsFlag = true;
       let waypoint = [];
       // let wt = {
       //   location : { lat : this.driverDropLat, lng: this.driverDropLng},
       //   stopover : false
       // }
     
       // waypoint.push(wt)
      
       this.origin = {
        lat: this.stops[0].lat,
        lng: this.stops[0].lng
       }
       this.destination = {
         lat: this.stops[this.stops.length - 1].lat,
         lng: this.stops[this.stops.length - 1].lng
       }
      
      
       
       
       
        let wt1 = {
         location : { lat : this.stops[1].lat, lng: this.stops[1].lng},
         stopover : false
       }
       waypoint.push(wt1)


       let wt2 = {
         location : { lat : this.stops[2].lat, lng: this.stops[2].lng},
         stopover : false
       }
       waypoint.push(wt2)
       this.waypoints = waypoint;
     
       
     }
      if(this.isArrived())
        { 
          this.renderOptions['preserveViewport']=true; 
        }
      this.lat = this.driverLat;
      this.lng = this.driverLng;

      
      
    },error => {
      this.router.navigateByUrl('notFound')
    })
  }

angleFromCoordinate(lat1, long1, lat2, long2) {
  let dLon = (long2 - long1);
  let y = Math.sin(dLon) * Math.cos(lat2);
  let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
      * Math.cos(lat2) * Math.cos(dLon);

  let brng = Math.atan2(y, x);
  brng = this.radians_to_degrees(brng);
  brng = (brng + 360) % 360;
  brng = 360 - brng;
  return brng;
}
radians_to_degrees(radians){
  let pi = Math.PI;
  return radians * (180/pi);
}
showBarContent(){
  this.toggle = !this.toggle;
  if(this.toggle)
  this.height = 'calc(100vh - 351px)';
 else
  this.height = 'calc(100vh - 210px)';
}

closest(llng, listData) {
  var arr     = listData;
  var pnt     = llng;
  var distArr = [];
  var dist    = google.maps.geometry.spherical.computeDistanceBetween;

  for (let index in arr)
     distArr.push([arr[index], dist(pnt,arr[index]),index]);
      
  return distArr.sort(function(a,b){
      return a[1]-b[1];
  })[0];
}

isDriverStateChange(){
  if(this.driverCurrentState && this.driverCurrentState != this.driverState)
   return true
  return false
}

isWaitingForPickUp(){
  if(this.driverState){
  if(this.isArrived())
    return false
  let arr = ['WAITING_FOR_START' ,'TRIP_STARTED','ARRIVED']
   if( !this.driverRideId || this.driverRideId == this.rideId)
     {
       return arr.includes(this.driverState.toString());}
  else
    return true}
  return false
}

isArrived(){
  if(this.rideState)
  { let arr = ['COMPLETED' ,'CASH_COLLECTED','CASH_PENDING','DROPPED_OFF']
    return arr.includes(this.rideState.toString());}
  return false
}

isOnTheWay(){
  if(this.driverState && this.rideState)
   return this.driverState == 'ON_RIDE' && this.rideState == 'RIDER_PICKED_UP' ? true : false; 
  return false
}

downloadApp(){
  if (navigator.userAgent.indexOf("Android") != -1) 
  {
    document.getElementById("downloadButton").setAttribute("href","https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.blusmart.rider&hl=en_IN");
 }
  if (navigator.userAgent.indexOf("like Mac") != -1) 
  { document.getElementById("downloadButton").setAttribute("href","https://apps.apple.com/us/app/blusmart-safe-electric-cabs/id1460355618");
  } else{
    document.getElementById("downloadButton").setAttribute("href","https://play.google.com/store/apps/details?id=com.blusmart.rider&hl=en_IN"); 
  }
}

isNotRental(){
  let arr = ['LIVE_RIDE','SCHEDULED_RIDE','PIN_DISPATCH','SCHEDULED_PIN_DISPATCH']
  return arr.includes(this.rideType.toString());
}
startTimer(){
  this.timeLeft = 0;
  if(this.timerSubscription)
  this.timerSubscription.unsubscribe()
  let stepArray = []

  for(let step in this.legs.steps)
    if( this.legs.steps[step].duration.value > 45)
    { this.timeLeft = this.timeLeft + Math.ceil(this.legs.steps[step].duration.value /60)
      stepArray.push(Math.ceil(this.legs.steps[step].duration.value /60))
    }
 // console.log(stepArray)
  let initialTime = stepArray[0]
  let initialIndex = 0
  this.timerSubscription =interval(60000).subscribe(
   (x) => {       if(this.i==initialTime)
                     {
                       this.timeLeft = this.timeLeft - stepArray[initialIndex];
                       if(stepArray[initialIndex+1] || this.timeLeft > 0){
                       initialTime = stepArray[initialIndex+1]+initialTime;
                       initialIndex = initialIndex+1
                       }else{
                         this.timerSubscription.unsubscribe()
                       }

                     }
                  this.i++;       
           });
}
middlePoint(lat1, lng1, lat2, lng2) {
let dLng = this.toRad(lng2-lng1);

  lat1 = this.toRad(lat1);
  lat2 = this.toRad(lat2);
  lng1 = this.toRad(lng1);

  var bX = Math.cos(lat2) * Math.cos(dLng);
  var bY = Math.cos(lat2) * Math.sin(dLng);
  var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
  var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);
  this.lat = this.toDeg(lat3)
  this.lng = this.toDeg(lng3)
  return;
}
toRad(Value) 
{
        return Value * Math.PI / 180;
}
toDeg(value)
    { return value * 180 / Math.PI;
    }
    
showAnimation(i, stops){
 return stops[i+1].status == 'REACHED' ? true: false;
}

getOtp(type){
  let sendOtpDto = {
    phone: this.riderPhone,
    callingCode : this.callingCode,
    otpSentCount : this.resendCount,
    rideRequestId : this.rideRequestId
  }

  this.resetOTPModal();
  this.clearOtp();

  if(this.platform == 'WEB'){
    if(type == 'send'){
      this.resendCount = 0;  
      this.displayOtpDialog = true;
      this.cancelRideDialog =false;
    }
    this._shareRide.getOTP(sendOtpDto).subscribe(res=>{
    })
  this.toggleTimer();
  }
  
  else{
  this.displayOtpDialog = false;
  this.cancelRideDialog = true;
}
}


resendOtp(){
  this.isOtpSent = true;
  setTimeout(()=>{
    this.isOtpSent = false;
  },3000)
  ++this.resendCount;
  this.getOtp('resend')
  this.resendCount = this.resendCount > 2 ? 0 : this.resendCount;
  this.toggleTimer()
}


verifyOtp(){
  let verifyOtpDto = {
    otp : this.otpValue.toString(),
    rideRequestId: this.rideRequestId
  }
this._loader.show()
  this._shareRide.verifyOTP(verifyOtpDto).subscribe(res=>{
    this._loader.hide()
    this.subscription.unsubscribe()
    if(res['statusCode'] >= 300){
      if(res['message'] == 'Sorry! Your ride is already started and cannot be cancelled at this moment.'){
        this.displayOtpDialog = false;
        Swal.fire({
          icon: 'error',
          title: 'Ride Started !',
          text: res['message']
        })
      }


      this.isIncorrectOtp = true;
      
    }

      else{
        this.isIncorrectOtp = false;
        this.router.navigate(['/reasons'])
        
      }
      
    }
  )
}


toggleTimer(){
  let otpTimer = 20;
  this.otpTimerToDisplay = `${otpTimer}`
  this.isResendDisable = true;
  let timer = setInterval(()=>{
    if(otpTimer == 1){
      --otpTimer;
      this.otpTimerToDisplay = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`
      this.isResendDisable = false;
      if(this.resendCount == 2){
        this.isResendHide = true;
        this.resendTitle = "Received OTP via call?"
      } 
      else{
        this.isResendHide = false;
      } 
      clearInterval(timer)
    }
    else{
      --otpTimer;
      this.otpTimerToDisplay = otpTimer < 10 ? `0${otpTimer}` : `${otpTimer}`
      return otpTimer
    }
  }, 1000)
}


resetOTPModal(){
  this.input1 = undefined;
  this.input2 = undefined;
  this.input3 = undefined;
  this.input4 = undefined;
  this.input5 = undefined;
  this.input6 = undefined;
  this.otpEntered = false;
  this.attempts =0
  this.resendOtpEnabled = false;
  this.showFailedOTPMessage = false;

}



handeOtpChange(value: any): void {
  this.isIncorrectOtp = false;
  for (const iterator of value) {
    if(iterator =='')
      this.otpEntered = false;  
      this.isVerifyOtpDisabled = true;

  }
}

handleFillEvent(value: any): void {
  this.otpEntered =true;
  this.otpValue =value;
  this.isVerifyOtpDisabled = false;
}

clearOtp(){
  this.ngxOtp.clear();
}

cancelRideAction(){
    let cancelRideDto ={
      action: 'CANCEL',
      rideRequestId: this.rideRequestId
    }
    this._loader.show()
    this._shareRide.cancelRide(this.rideRequestId, cancelRideDto, this.token).subscribe(res=>{
      this._loader.hide()
      this.subscription.unsubscribe()
      if(res['statusCode'] == 200){
        this.router.navigate(['/reasons'])
      }

      else{
        this.cancelRideDialog = false;
        if(res['message'] == 'Sorry! Your ride is already started and cannot be cancelled at this moment.'){
          Swal.fire({
            icon: 'error',
            title: 'Ride Started !',
            text: res['message']
          })
        }

        else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Technical Error'
        })
      }
      }
    })
  }

  closeDialog(type){
    if(type == 'otpScreen'){
      this.displayOtpDialog = false;
    }
    else{
    this.cancelRideDialog = false;
    }
  }
  
}










