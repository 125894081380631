import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TripComponent } from './trip/trip.component';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { ReasonsComponent } from './reasons/reasons.component';
import { RideScreenComponent } from './components/ride-screen/ride-screen.component';

const routes: Routes = [
  {
    path:'',
    component: PageNotFoundComponent,
  },

  {
    path: 'reasons',
    component: ReasonsComponent,
  },

  {
    path:'notFound',
    component: PageNotFoundComponent,
    pathMatch: 'full',
    // canActivate:[CanActivateRouteGuard]
  },
  {
    path:':accessToken',
    component: RideScreenComponent,
  }

 


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
