import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AgmCoreModule,GoogleMapsAPIWrapper} from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';  
import { AgmOverlays } from "agm-overlays"
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TripComponent } from './trip/trip.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http'; 
import {TimeDifference} from '../app/pipes/pipe.pipe';
import { estimatedDistance } from '../app/pipes/pipe.pipe';
import {DialogModule} from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { CookieService } from 'ngx-cookie-service';
import { ReasonsComponent } from './reasons/reasons.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { RideScreenComponent } from './components/ride-screen/ride-screen.component';
import { ScheduledRentalsComponent } from './components/scheduled-rentals/scheduled-rentals.component';
import { MapComponent } from './components/map/map.component';
import { OtpComponent } from './components/otp/otp.component';
import { DriverDetailsComponent } from './components/driver-details/driver-details.component';

import { StopsComponent } from './components/stops/stops.component';
import { RentalPackageComponent } from './components/rental-package/rental-package.component';
import { CancelRideComponent } from './components/cancel-ride/cancel-ride.component';
import { EditStopsComponent } from './components/edit-stops/edit-stops.component';






@NgModule({
  declarations: [
    AppComponent,
    TripComponent,
    PageNotFoundComponent,
    TimeDifference,
    estimatedDistance,
    ReasonsComponent,
    RideScreenComponent,
    ScheduledRentalsComponent,
    MapComponent,
    OtpComponent,
    DriverDetailsComponent,
    
    StopsComponent,
         RentalPackageComponent,
         CancelRideComponent,
         EditStopsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AgmDirectionModule,
    AgmOverlays,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    NgxOtpInputModule,
    FormsModule,
    DialogModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBXp-zcfbrxAYyUnz0WpVIrH5jsmF46DIc',
      region: 'IN'
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    })
  ],
  exports:[TimeDifference],
  providers: [GoogleMapsAPIWrapper, CookieService],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
