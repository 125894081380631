<div class="main-container">
    <div class="stops-container">
        <div class="stops-title">
            <span>Ride Route</span>
            <img src="../../../assets/expand-arrow.svg" alt="">
        </div>

        <div class="stops-card">
            <div *ngFor="let stop of stopsListDto; let i = index" class="stops">
               
                <div class="pickup-location-container" *ngIf="i == 0">
                    <div class="marker-container">
                        <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="marker">
                            <path d="M5 13C4.93672 13 4.87416 12.9874 4.81638 12.963C4.75861 12.9387 4.70693 12.9032 4.66469 12.8588C4.47436 12.6592 0 7.91571 0 4.70819C0 3.4595 0.526784 2.26196 1.46447 1.379C2.40215 0.49604 3.67392 0 5 0C6.32608 0 7.59785 0.49604 8.53553 1.379C9.47322 2.26196 10 3.4595 10 4.70819C10 7.93892 5.52564 12.6601 5.33531 12.8598C5.29298 12.904 5.24126 12.9393 5.18349 12.9635C5.12572 12.9876 5.06321 13.0001 5 13ZM5 2.33831C4.50247 2.33831 4.01611 2.47722 3.60241 2.73749C3.18871 2.99775 2.86626 3.36767 2.67582 3.80049C2.48538 4.23331 2.4355 4.70958 2.53249 5.16908C2.62948 5.62859 2.86899 6.0507 3.22073 6.38204C3.57247 6.71338 4.02064 6.93908 4.50859 7.03059C4.99654 7.1221 5.50235 7.07532 5.96206 6.89616C6.42178 6.717 6.81476 6.41351 7.09131 6.02406C7.36787 5.63461 7.51558 5.17669 7.51578 4.70819C7.51526 4.08013 7.2502 3.47791 6.77876 3.03363C6.30731 2.58935 5.66797 2.33929 5.00099 2.33831H5Z" fill="#13AE5C"/>
                            </svg>
                            <div class="track-line"></div>
                            <span class="estimated-distance">{{stop['distanceToNextStop'] | estimatedDistance}} Kms</span>
                    </div>

                    <div class="pickup-location">
                        <span>{{stop['place']}}</span>
                        <span>(Pickup: {{stopsEtas.eta}})</span>
                    </div>
                </div>


                <div class="drop-location-container" *ngIf="i > 0">
                    <div class="marker-container">
                            <span class="marker stops-index">{{i}}</span>
                            <div class="track-line" *ngIf="i != stopsListDto.length - 1"></div>
                            <span class="estimated-distance" *ngIf="stop['distanceToNextStop']">{{stop['distanceToNextStop'] | estimatedDistance}} Kms</span>
                    </div>

                    <div class="drop-location">
                        <span>{{stop['place']}}</span>
                        <span *ngIf="i != stopsListDto.length - 1">(Drop-off: {{stopsEtas.etd}})</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>