import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { estimatedDistance } from 'src/app/pipes/pipe.pipe';


@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.css']
})
export class StopsComponent implements OnInit, OnChanges {

  @Input() stopsListDto = [];
  @Input() stopsEtas = {eta: null, etd: null};
  currentStopIndex = 0;
  nextStopIndex = this.currentStopIndex + 1;

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.stopsEtas);
    this.setEtaData();
    this.setDtoData();
  }

  ngOnInit(): void {
  }

  setDtoData(){
  }

  setEtaData(){

  }



}
