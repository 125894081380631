<div>
    <div  class="head-container"></div>
    <div class="container-fluid">
    <div class="row " style="padding-top: 30px;">
        <div class="col-lg-6 col-md-6 col" style="padding-left: 10vw; padding-bottom: 10vh;margin:auto"> 
            <div style="max-width: 500px;"> 
            <h1 style="padding-bottom: 20px;">Sorry, we couldn’t find that page</h1>
            <span style="line-height: 40px; width: 200px;">We have shifted a few things around, and your page must have gotten lost. Try retyping the address or just head back to our home page.</span>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col" style="margin:auto">
            <img src='../../assets/notFound.svg' class="center">
        </div>
    </div>

    <button (click)="testUat()">Click</button>
</div>
</div>
