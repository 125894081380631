<agm-map [zoom]="8" [maxZoom]="20" [minZoom]="mapDto['mapZoom']" [disableDefaultUI]="true" class="google-map" [styles]="agmCredentials.options"> 
    <agm-direction [renderOptions]="agmCredentials.renderOptions" [origin]="agmCredentials.pick" [destination]="agmCredentials.destination"
    [markerOptions] = "agmCredentials.markerOptions" [waypoints]="agmCredentials.stops">
    </agm-direction>

    <agm-overlay [latitude]="agmCredentials.carLocation['lat']" [longitude]="agmCredentials.carLocation['lng']">
        <img src="../../../assets/blu-car-icon.svg" alt="" class="car-icon">
    </agm-overlay>

    <agm-overlay [latitude]="agmCredentials.pick['lat']" [longitude]="agmCredentials.pick['lng']">
        
    </agm-overlay>
</agm-map>